import React from 'react';
import './App.css';
import Calculator from './Components/Calculator/Calculator';

function App() {
  return (
    <div>
        <h1>Calculator</h1>
        <Calculator/>
    </div>
  );
}

export default App;
