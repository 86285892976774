import React, { useState } from 'react';
import './calculator.css';
import ResultComponent from './Components/ResultComponent';
import KeyPadComponent from "./Components/KeyPadComponent";
const API_URL = process.env.REACT_APP_API_URL;

/**
 * Simple calculator component
 */
function Calculator() {
  const [formula, setFormula] = useState("");
  const [history, setHistory] = useState("");

  function calculate() {
    let formData = new FormData();
    formData.append('formula', formula);
    fetch("https://calculatorapi.sophia.lu/", { method: 'POST', body: formData })
      .then(response => response.json())
      .then(
        response => {
          setHistory(formula);
          setFormula(response.result);
        }
      )
      .catch(err => {
        console.log(err)
      });
  };

    function type(value) {
      setFormula(formula + value);
    };

    function reset() {
      setFormula("");
    };

    function backspace() {
      setFormula(formula.slice(0, -1));
    };

  return (
      <div className="calculator-body">
          <ResultComponent
            history={history}
            formula={formula}/>
          <KeyPadComponent
            type={type}
            reset={reset}
            backspace={backspace}
            calculate={calculate}/>
      </div>
  );
}

export default Calculator;
