import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Container>
      <Row>
        <Col xs={12} md={12} lg={12}><App /></Col>
      </Row>
    </Container>
  </React.StrictMode>,
  document.getElementById('root')
);
