import * as React from 'react';

/**
 * Result component to display the result and history
 */
function ResultComponent(props) {
    return (
        <div className="result">
            <p className="history">{props.history}</p>
            <p className="formula">{props.formula}</p>
        </div>
    );
}

export default ResultComponent;
