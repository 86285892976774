import * as React from 'react';
import Button from 'react-bootstrap/Button';

/**
 * Keypad component to display the buttons
 */
function KeyPadComponent(props) {
    return (
      <div className="button">
              <Button className="btn-cor" name="CE" onClick={(e) => props.backspace(e.target.name)}>CE</Button>
              <Button className="btn-cor" name="C" onClick={(e) => props.reset(e.target.name)}>C</Button><br/>

              <Button name="1" onClick={(e) => props.type(e.target.name)}>1</Button>
              <Button name="2" onClick={(e) => props.type(e.target.name)}>2</Button>
              <Button name="3" onClick={(e) => props.type(e.target.name)}>3</Button>
              <Button className="btn-operator" name="+" onClick={(e) => props.type(e.target.name)}>+</Button>


              <Button name="4" onClick={(e) => props.type(e.target.name)}>4</Button>
              <Button name="5" onClick={(e) => props.type(e.target.name)}>5</Button>
              <Button name="6" onClick={(e) => props.type(e.target.name)}>6</Button>
              <Button className="btn-operator" name="-" onClick={e => props.type(e.target.name)}>-</Button>

              <Button name="7" onClick={(e) => props.type(e.target.name)}>7</Button>
              <Button name="8" onClick={(e) => props.type(e.target.name)}>8</Button>
              <Button name="9" onClick={(e) => props.type(e.target.name)}>9</Button>
              <Button className="btn-operator" name="*" onClick={(e) => props.type(e.target.name)}>x</Button>


              <Button name="." onClick={e => props.type((e).target.name)}>.</Button>
              <Button name="0" onClick={e => props.type((e).target.name)}>0</Button>
              <Button className="btn-result" name="=" onClick={(e) => props.calculate(e.target.name)}>=</Button>
              <Button className="btn-operator" name="/" onClick={(e) => props.type(e.target.name)}>÷</Button>
          </div>
    );
}

export default KeyPadComponent;
